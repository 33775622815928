<template>
  <div>
    <div class="app-store-header">
      <img src="~@/assets/illustration/app-store-shape.svg" class="app-store-image" />
    </div>

    <div class="app-store-container">
      <router-view />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      applicationName: "",
      uploadLoading: false
    };
  }
};
</script>

<style lang="scss">
@import "~@/styles/variables.scss";
@import "~@/styles/mixin.scss";

.app-store-header {
  height: 210px;
  background-color: $header-bg-color;
  position: fixed;
  left: 0;
  top: 50px;
  width: 100vw;
  color: #fff;
  text-align: center;

  .app-store-image {
    position: absolute;
    left: 300px;
    bottom: 0;
    width: 200px;
    height: 200px;
    opacity: 0.1;
  }
}

.header-slot {
  height: 210px;
  color: #fff;
  position: fixed;
  top: 50px;
  left: 0;
  width: 100%;
  box-sizing: border-box;
  padding: 30px 20px 0 20px;

  .align-left-content {
    text-align: left;
    width: 80%;
    margin: 0 auto;
  }

  .align-center-content {
    text-align: center;
    height: 100%;
  }

  .app-store-title {
    font-size: 28px;
  }

  .app-store-description {
    font-size: 14px;
  }

  .app-search-input {
    margin-top: 20px;
    width: 40%;
  }

  .upload-tips {
    position: absolute;
    right: 20px;
    top: 30px;
    padding: 18px;
    border-radius: 8px;
    background: #8e2de2;
    background: -webkit-linear-gradient(to right, #4a00e0, #8e2de2);
    background: linear-gradient(to right, #4a00e0, #8e2de2);

    text-align: left;
    width: 360px;

    .upload-tips-title {
      @include title(14px);
      color: #fff;
      margin-bottom: 6px;
    }

    .upload-tips-description {
      margin-bottom: 10px;
    }

    .upload-button {
      background-color: #8e2de2;
      border: none;
    }
  }
}

.app-store-container {
  margin-top: 200px;
  padding: 20px;
}
</style>
